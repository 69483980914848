import { SegmentType, NonTarget, OrderType } from "@schema";
// MR 候选目标客户 搜索页面 历史记录cache缓存名称
export const SURVEY_DOCTOR_HISTORY_LIST = "SURVEY_DOCTOR_HISTORY_LIST";
// MR 客户分级明细 搜索页面 历史记录cache缓存名称
export const MR_HCP_DETAIL_HISTORY_LIST = "MR_HCP_DETAIL_HISTORY_LIST";
// DM 目标医院 LIST搜索页面 历史记录cache缓存名称
export const HOSPITAL_HISTORY_LIST = "HOSPITAL_HISTORY_LIST";
// DM 客户分级明细 搜索页面 历史记录cache缓存名称
export const DM_HCP_DETAIL_HISTORY_LIST = "DM_HCP_DETAIL_HISTORY_LIST";
export enum SEARCH_ROUTER_FROM {
    surveyDoctor = "surveyDoctor",
    mrHcpDetail = "mrHcpDetail",
    hospital = "hospital",
    dmHcpDetail = "dmHcpDetail",
}
export const CURRENT_AGENT = "CURRENT_AGENT";
export const MINI_PROGRAME_AGENT = "MINI_PROGRAME_AGENT";
// MR DOCTOR LIST查询页面 按标签筛选option

// 标签筛选key
export enum DOCTOR_OPT_KEY {
    all = "", // 全部或默认
    isDcr = "isDcr", // 是否是新增客户
    isTarget = "isTarget", // 是否为目标客户
    isNonTarget = "isNonTarget", // 是否为非目标客户
    isNurse = "isNurse",
    hcoName = "hcoName", // 医院名称
    segment = "segment", // 客户等级
    isSurvey = "isSurvey", // 是候选目标客户
    unSurvey = "unSurvey", // 非候选目标客户
}

export const LABEL_OPT_LIST = [
    {
        name: "全部",
        code: void 0,
    },
    {
        name: "目标客户",
        code: DOCTOR_OPT_KEY.isTarget,
    },
    {
        name: "新增客户",
        code: DOCTOR_OPT_KEY.isDcr,
    },
    {
        name: "非目标客户",
        code: DOCTOR_OPT_KEY.isNonTarget,
    },
    {
        name: "护士",
        code: DOCTOR_OPT_KEY.isNurse,
    },
];
// MR DOCTOR DETAIL客户分级明细页面 排序筛选option
export const FILTER_OPT_LIST = [
    {
        name: "默认排序",
        code: OrderType.default,
    },
    {
        name: "按客户分级从高到低",
        code: OrderType.segment,
    },
];

// MR 客户等级筛选列表
export const SEGMENT_LIST = [
    // {
    //     name: SegmentType.ST,
    //     code: SegmentType.ST,
    // },
    {
        name: SegmentType.T1,
        code: SegmentType.T1,
    },
    {
        name: SegmentType.T2,
        code: SegmentType.T2,
    },
    {
        name: SegmentType.T3,
        code: SegmentType.T3,
    },
    {
        name: SegmentType.Influencer,
        code: SegmentType.Influencer,
    },
];

export const SEGMENT_COLORS = {
    // [SegmentType.ST]: "#1b98fe",
    [SegmentType.T1]: "#00C9CB",
    [SegmentType.T2]: "#00C9CB",
    [SegmentType.T3]: "#00C9CB",
    [SegmentType.Influencer]: "#00C9CB",
    [NonTarget.nonTarget]: "#fd9024",
};
export const SEGMENT_TAG_COLORS = {
    // [SegmentType.ST]: "#e8f5fe",
    [SegmentType.T1]: "#e9fafa",
    [SegmentType.T2]: "#00C9CB",
    [SegmentType.T3]: "#00C9CB",
    [SegmentType.Influencer]: "#00C9CB",
    [NonTarget.nonTarget]: "#fdf3eb",
};

// form 类型
export enum FORM_TYPE_KEY {
    Selection = "Selection", // 下拉，Text
    Text = "Text", // 文本
    Number = "Number", // 数字
    Percentage = "Percentage", // 百分比
}
// form placeholder 默认值
export enum FORM_PLACEHOLDER_DEFAULT {
    Selection = "请选择", // 下拉，Text
    Text = "请输入内容", // 文本
    Number = "请输入数字", // 数字
    Percentage = "请输入百分比", // 百分比
}

// filter list title
export enum FILTER_LIST_TITLE {
    label = "按标签筛选",
    hco = "按医院筛选",
    sort = "排序",
    filter = "筛选",
    hcoName = "医院名称",
    product = "产品名称",
    segment = "客户分级",
    position = "代表名称",
}

export const SEGMENT_ASC = [
    SegmentType.ST,
    SegmentType.T1,
    SegmentType.T2,
    SegmentType.T3,
    SegmentType.Influencer,
];

export const BACK_ORG = "BACK_ORG";

export const NOT_NULL_FLAG = "NOT NULL";

export const NO_DATA_TEXT = "未找到相关数据";

export const SEARCH_PLACEHOLDER = "搜索医院名/客户名";

export const HELP_TEXT = "HELP_TEXT";

export const IGNORE_SUBMITTER = "系统继承";

export const TARGET_RANGE_LEVEL_ENUM = "TARGET_RANGE_LEVEL_ENUM";

export const NURSE_TITLES = [
    "护士",
    "护师",
    "主任护师",
    "副主任护师",
    "主管护师",
];

export enum ClientFilterTags {
    nonSummary = "未填写问卷",
    haveSegmentation = "有客户分级",
    adjustHcp = "本期调整",
    notUpdateSurvey = "6个月未更新",
    multipleRepresentatives = "同区域多代表医生数",
}
