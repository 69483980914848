import {isWeCom, isMiniProgram, Provide, CacheService, TOKEN, HttpUtil, navigateBackMiniProgram, isShortTokenExpired} from "@common";
import { ResponseCode, type User, Role } from "@schema";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { assign, startsWith, throttle, isEmpty, isUndefined } from "lodash";
import { showToast } from "@nutui/nutui";
import { showNotify } from "@components";
import { parse } from "qs";
import { sha256 } from "js-sha256";
import { userAccountDialog } from "./local-utils";
import { customRef } from "vue";
import router from '@router';
import { MINI_PROGRAME_AGENT } from "@constants";
import { PersonService } from "@services";

export interface IResultData {
    code: number;
    data?: any;
    token?: any;
    items?: any[];
    msg: string;
}
export enum Mode {
    development = "development",
    production = "production",
}

export interface Credential {
    token: string;
    time: string;
    sign: string;
    data?: Dict<any>;
}
export const CURRENT_USER = "CURRENT_USER";
export const TEMP_URL = "TEMP_URL";
// field 统一登录CR，三个同一个cache key
export const CACHE_KEY_NVS_WC_APP_ACCESS_TOKEN = "NVS-WC-APP-ACCESS-TOKEN";
export const CACHE_KEY_NVS_WC_APP_REFRESH_TOKEN = "NVS-WC-APP-REFRESH-TOKEN";
export const CACHE_KEY_NVS_WC_APP_SESSION_KEY = "NVS-WC-APP-SESSION-KEY";
export const CACHE_KEY_LOGIN_STATUS = "LOGIN_STATUS";

export const ACT_CODE = "ACT_CODE";
const ACT_SOURCES = ["ACT", "ACT-Lite", "APP"];
// 新增field app小程序跳转
const FIELD_APP_SOURCES = ["FIELD-APP"];
type SOURCE_KEY = "ACT" | "ACT-Lite" | "FIELD-APP" | "APP" | void;

export class HttpService extends HttpUtil {
    private signCredential: Credential = { token: "", time: "", sign: "" };
    private loadingMap: Dict<boolean> = {};
    private actUrl!: string;
    private fromFieldApp!: undefined | boolean;
    private trigger!: () => void;
    public isActRedirect = customRef((track, tigger) => {
        this.trigger = tigger;
        return {
            set: () => void 0,
            get: () => {
                track();
                return !!this.actUrl || this.fromFieldApp;
            },
        };
    });
    private showLoadingThrottle = throttle(
        () => {
            showToast.loading("", {
                bgColor: "rgba(0, 0, 0, 0.3)",
                duration: 0,
            });
        },
        500,
        {
            trailing: false,
        }
    );

    public doLogin = throttle(
        async () => {
            const query = parse(location.search, {
                decoder: (str) => str,
                ignoreQueryPrefix: true,
            });
            const sessionKey =
                query.sessionkey ||
                this.cache.get(CACHE_KEY_NVS_WC_APP_SESSION_KEY);
            const refreshToken = this.cache.get(
                CACHE_KEY_NVS_WC_APP_REFRESH_TOKEN
            );
            const accessToken = this.cache.get(
                CACHE_KEY_NVS_WC_APP_ACCESS_TOKEN
            );
            const loginStatus = this.cache.get(CACHE_KEY_LOGIN_STATUS);
            if (
                !["uat", "prod"].includes(import.meta.env.VITE_ENV as string) &&
                !isWeCom
            ) {
                (await import("./local-utils")).localLogin(
                    async (userAccount) => {
                        await this.login({
                            userAccount,
                        });
                    }
                );
            } else if (
                (sessionKey || refreshToken) &&
                loginStatus !== ResponseCode.unauthorized
            ) {
                this.cache.set(TEMP_URL, location.href);
                await this.login({
                    sessionKey,
                    accessToken,
                    refreshToken,
                });
                console.log("error！重定向了", location.href);
                location.replace(location.href);
            } else if (!this.isSsocallback) {
                this.cache.set(TEMP_URL, location.href);
                location.replace(
                    import.meta.env.VITE_SSO_PATH +
                        `/mobile/authorize?appId=${
                            import.meta.env.VITE_APP_ID
                        }&RedirectUrl=${import.meta.env.VITE_SSO_REDIRECT_URL}`
                );
            }
        },
        10 * 10 ** 3,
        {
            trailing: false,
        }
    );

    private set token(val: string) {
        this.signCredential.token = val;
        this.cache.set(TOKEN, val);
    }

    private get isSsocallback(): boolean {
        return location.pathname.includes("/ssocallback");
    }

    constructor() {
        super();
        this.defaultConfig.baseURL = import.meta.env.VITE_API_PATH;
        this.defaultConf.headers = {
            "Content-Type": "application/json",
        };
        this.cache = Provide(CacheService);
        this.person = Provide(PersonService);
        this.signCredential.token = this.cache.get(TOKEN) || "";
        (async () => {
            if (this.isSsocallback) {
                const query = parse(location.search, {
                    ignoreQueryPrefix: true,
                });
                await this.login({
                    code: query.code as string,
                });
                const earlierUrl = this.cache.get(TEMP_URL) || "/";
                this.cache.remove(TEMP_URL);
                location.replace(earlierUrl);
                return;
            }
            setTimeout(async () => {
                await this.getACTUrl();
                await this.checkMiniProgram();
            }, 500);
        })();
    }

    get defaultConf(): AxiosRequestConfig {
        return this.defaultConfig;
    }

    public async getACTUrl(): Promise<void> {
        const query: {
            code?: string;
            source?: SOURCE_KEY;
        } = parse(location.search, {
            decoder: (str) => str,
            ignoreQueryPrefix: true,
        });
        let actCode = this.cache.get(ACT_CODE);
        if (
            query.source &&
            (ACT_SOURCES.includes(query.source) ||
                FIELD_APP_SOURCES.includes(query.source)) &&
            query.code
        ) {
            actCode = query.code;
            this.cache.set(ACT_CODE, actCode);
        } else {
            this.cache.remove(ACT_CODE);
        }
        if (actCode) {
            try {
                const res = await this.post(
                    "/front/base/common/person/v1/decryptActCode",
                    actCode
                );
                const obj = JSON.parse(res);
                if (query.source && ACT_SOURCES.includes(query.source)) {
                    this.actUrl = obj.retURL + `?source=SPM&code=${actCode}`;
                } else if (
                    query.source &&
                    FIELD_APP_SOURCES.includes(query.source)
                ) {
                    const base64String = obj.toArgs;
                    const jsonString = atob(base64String);
                    const jsonObject = JSON.parse(jsonString);
                    console.log("打印res:", res);
                    console.log("重定向的路由对象", jsonObject);
                    // console.log("router", router);
                    router.replace(jsonObject);
                    // console.log("跳转调用完毕", location);
                    /**
                     * 从小程序跳转过来，携带代管信息，需要直接切换代管
                     * 存一下从小程序带过来的代理信息。用来控制代管信息悬浮窗展示，同时用来区分DM查看下属的时候的代管
                     */
                    const {
                        query: { agentPositionCode, agentName },
                    } = jsonObject;
                    if (agentPositionCode) {
                        this.cache.set(MINI_PROGRAME_AGENT, {
                            agentPositionCode,
                            agentName,
                        });
                        this.person.switchAgent(
                            agentPositionCode,
                            agentName,
                            true,
                            false
                        );
                    }
                }
                this.trigger();
            } catch (e) {
                this.cache.remove(ACT_CODE);
            }
        }
    }

    /**
     * 检测是否小程序跳转
     */
    public async checkMiniProgram(): Promise<void> {
        const query: {
            code?: string;
            source?: SOURCE_KEY;
        } = parse(location.search, {
            decoder: (str) => str,
            ignoreQueryPrefix: true,
        });
        console.log("Current query =>", query);
        this.fromFieldApp =
            (query.source && FIELD_APP_SOURCES.includes(query.source)) ||
            isMiniProgram;
        console.log("Check Source from fieldApp", this.fromFieldApp);
    }

    public backToACT(): void {
        if (this.actUrl) {
            window.location.href = this.actUrl;
            this.cache.remove(ACT_CODE);
        }
        if (this.fromFieldApp) {
            navigateBackMiniProgram();
        }
    }

    private async login(param: {
        code?: string;
        sessionKey?: string;
        accessToken?: string;
        refreshToken?: string;
        userAccount?: string;
        isRolePlayAs?: boolean;
    }): Promise<void> {
        await this.saveCurrentUser(
            await this.post("/front/base/common/authority/v1/login", param)
        );
    }

    private async loginAsRolePlay(userAccount: string): Promise<void> {
        await this.saveCurrentUser(
            await this.post(
                "/front/base/common/person/v1/loginAsRolePlay",
                userAccount
            )
        );
    }

    protected async resultFilter(
        result: Promise<AxiosResponse<any>>
    ): Promise<any> {
        let res: any;
        try {
            res = await result;
        } catch (e: any) {
            res = e.response;
        } finally {
            for (const key in this.loadingMap) {
                if (startsWith(res.request.responseURL, key)) {
                    delete this.loadingMap[key];
                }
            }
            this.hideLoadingDebounce();
            this.catchError(res);
        }
        return res?.data?.data;
    }

    protected requestFilter(url: string): Promise<void> {
        this.loadingMap[
            location.origin +
                import.meta.env.VITE_API_PATH +
                url.replace(/^\//, "")
        ] = true;
        this.showLoadingThrottle();
        return Promise.resolve();
    }

    protected mergeData(data: Dict<any>): Dict<any> {
        return assign({}, this.signCredential, { data });
    }

    protected catchError(res: AxiosResponse<any>): void {
        if (res.status === 200) {
            switch (res.data.code) {
                case ResponseCode.fail:
                    showNotify({
                        msg: res.data.msg,
                    });
                    throw new Error(res.data.msg);
                case ResponseCode.unauthorized:
                    console.log("msg ===> ", res.data.msg);
                    this.cache.remove(CACHE_KEY_LOGIN_STATUS);
                    // 非shortToken过期
                    if (!isShortTokenExpired(res.data.msg)) {
                        this.cache.remove(CACHE_KEY_NVS_WC_APP_ACCESS_TOKEN);
                        this.cache.remove(CACHE_KEY_NVS_WC_APP_REFRESH_TOKEN);
                        this.cache.remove(CACHE_KEY_NVS_WC_APP_SESSION_KEY);
                        this.cache.set(
                            CACHE_KEY_LOGIN_STATUS,
                            ResponseCode.unauthorized
                        );
                    }
                    this.doLogin();
                    throw new Error(res.data.msg);
            }
        } else {
            showNotify({
                msg: "网络或服务器异常",
            });
            throw new Error(res.data);
        }
    }

    private async saveCurrentUser(data: User): Promise<void> {
        this.token = data.shortToken as string;
        data.originRoleCode = data.roleCode;
        delete data.shortToken;
        this.cache.set(CURRENT_USER, data);
        await this.specifyRoleHandler(data.roleCode);
        this.cache.set(CACHE_KEY_NVS_WC_APP_ACCESS_TOKEN, data.wx_access_token);
        this.cache.set(
            CACHE_KEY_NVS_WC_APP_REFRESH_TOKEN,
            data.wx_refresh_token
        );
        this.cache.set(CACHE_KEY_LOGIN_STATUS, ResponseCode.success);
    }

    protected getMergeConfig(
        config?: AxiosRequestConfig,
        data?: any
    ): AxiosRequestConfig {
        const key = this.cache.get(CURRENT_USER)?.transmitEncryptionKey || "";
        const sign = key && sha256(JSON.stringify(data) + key);
        if (config) {
            return {
                ...this.pdefaultConfig,
                ...config,
                headers: {
                    ...this.pdefaultConfig?.headers,
                    ...config?.headers,
                    sign,
                },
            };
        } else {
            return {
                ...this.pdefaultConfig,
                headers: {
                    ...this.pdefaultConfig?.headers,
                    sign,
                },
            };
        }
    }
    private hideLoadingDebounce(): void {
        if (isEmpty(this.loadingMap)) {
            showToast.hide();
        }
    }

    /**
     * @description 检查是否是BUFE，如果是，则走RolePlay登录流程
     * @param user
     */
    private async specifyRoleHandler(roleCode: Role): Promise<void> {
        if (roleCode === Role.BU_FE) {
            const userAccount = await userAccountDialog("请输入8位目标员工号");
            if (userAccount) {
                try {
                    await this.loginAsRolePlay(userAccount as string);
                    location.replace(location.href);
                } catch (e) {
                    await this.specifyRoleHandler(roleCode);
                }
            }
        }
    }
}
